// AuthComponent.tsx
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { SocialIcon } from "react-social-icons";
import "react-social-icons/github";

// Component that handles authentication for content that's behind a
// subscription wall. Currently supports auth with Google and GitHub.
const AuthComponent: React.FC = () => {
  const signInWithGoogle = () => {
    window.location.href = "/api/auth";
  };

  const signInWithGitHub = () => {
    window.location.href = "/api/auth-github";
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mb-4">
        <StaticImage
          src="../images/mo_welcome.png"
          alt="Welcome to the VIP club"
          placeholder="blurred"
          height={250}
          width={250}
          transformOptions={{ fit: "inside" }}
        />
      </div>
      <div className="font-nunitoSans text-3xl text-center font-semibold mb-8 text-text">
        To read the rest of the post, subscribe and never miss an issue!
      </div>
      <div className="flex flex-col space-y-4">
        <button
          onClick={signInWithGoogle}
          className="text-text py-2 px-3 rounded-full border hover:bg-gray-50"
        >
          <div className="flex flex-row space-x-2 justify-center items-center">
            <img src="/google-logo.svg" alt="Google Icon" className="h-6" />
            <div className="text-center flex-grow">Login with Google</div>
          </div>
        </button>
        <button
          onClick={signInWithGitHub}
          className="text-text py-2 px-3 rounded-full border hover:bg-gray-50"
        >
          <div className="flex flex-row space-x-2 justify-center items-center">
            <SocialIcon network="github" style={{ height: 24, width: 24 }} />
            <div className="text-center flex-grow">Login with GitHub</div>
          </div>
        </button>
      </div>
      <div className="m-4 font-inter text-center">
        Already a subscriber? Use the same account to login again.
      </div>
    </div>
  );
};

export default AuthComponent;
