import "./articlePost.css";
import "./gist.css";
import Divider from "@material-ui/core/Divider";
import Layout from "../core/Layout";
import { MDXRenderer } from "gatsby-plugin-mdx";
import NewsletterRow from "../newsletter/NewsletterRow";
import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import AuthorCard from "./AuthorCard";
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  getSrc,
} from "gatsby-plugin-image";
import TwitterFollowChip from "../../tailwindComponents/TwitterFollowChip";
import Avatar from "../../tailwindComponents/Avatar";
import SimilarContentRow from "../../tailwindComponents/SimilarContentRow";
import { shuffleArray } from "../../utils/ArrayUtils";
import { Container, Hidden } from "@material-ui/core";
import AdCTA from "../../tailwindComponents/AdCTA";
import ShareRow from "../../tailwindComponents/ShareRow";
import { MDXProvider } from "@mdx-js/react";
import BlurryNewsletterRow from "../../tailwindComponents/BlurryNewsletterRow";
import { format } from "date-fns";
import { isMobileDevice } from "../../utils/UserAgentUtils";
import subscribe from "../../api/subscribe";
import NewsletterMainSponsor from "../../tailwindComponents/NewsletterMainSponsor";
require("prismjs/themes/prism-tomorrow.css");

interface ArticlePostProps extends RouteComponentProps {
  pageContext: {
    data: any;
    featuredJobs: any[];
    otherArticles: any[];
    isArticle: boolean;
  };
}

export default function ArticlePost(props: ArticlePostProps) {
  const { frontmatter, body } = props.pageContext.data;
  const articleUrl = "https://www.jetpackcompose.app" + frontmatter.slug;
  const twitterShareUrl =
    'http://twitter.com/share?text=I just read "' +
    frontmatter.title +
    '" by @vinaygaba&url=https://www.jetpackcompose.app' +
    frontmatter.slug +
    "&hashtags=\nAndroidDev,JetpackCompose";
  // https://developers.google.com/search/docs/advanced/structured-data/faqpage

  const heroImageOriginalUrl = getSrc(frontmatter.heroImageUrl);
  const containsSubscriptionOnlyContent =
    frontmatter.containsSubscriptionOnlyContent;
  const heroImage: IGatsbyImageData | undefined = getImage(
    frontmatter.heroImageUrl?.childImageSharp?.gatsbyImageData
  );

  const [randomArticles, setRandomArticles] = useState<any[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  const [isSubscribed, setIsSubscribed] = useState(false);

  const CACHE_DURATION_MS = 60 * 60 * 1000; // 1 hour in milliseconds
  const ONE_MONTH_IN_MS = 30 * 24 * 60 * 60 * 1000; // 14 days in milliseconds
  const DISPATCH_SUBSCRIBED_KEY = "dispatch_subscribed";

  // Add this before the useEffect for session checking
  const needsSessionCheck = () => {
    if (typeof window === "undefined") return false;

    const storedData = localStorage.getItem(DISPATCH_SUBSCRIBED_KEY);
    if (!storedData) return true;

    try {
      const { subscribed, timestamp } = JSON.parse(storedData);
      if (!subscribed || !timestamp) return true;

      const timeSinceLastCheck = Date.now() - timestamp;
      return timeSinceLastCheck > ONE_MONTH_IN_MS;
    } catch (error) {
      // If there's any error parsing the stored data, better to check the session
      return true;
    }
  };

  useEffect(() => {
    // Check if user has a valid session
    const checkSession = async () => {
      // First check if we need to verify with the server
      if (!needsSessionCheck()) {
        const storedData = JSON.parse(
          localStorage.getItem(DISPATCH_SUBSCRIBED_KEY) || "{}"
        );
        setIsSubscribed(storedData.subscribed || false);
        return;
      }

      try {
        console.log("Checking session with server...");
        const response = await fetch("/api/check-session", {
          credentials: "include", // Important: include cookies in the request
        });
        const data = await response.json();
        console.log("Server response:", data);

        const isAuthenticated = response.ok && data.status === "authenticated";
        console.log("Setting auth status:", isAuthenticated);

        // Update localStorage with new timestamp
        localStorage.setItem(
          DISPATCH_SUBSCRIBED_KEY,
          JSON.stringify({
            subscribed: isAuthenticated,
            timestamp: Date.now(),
          })
        );

        setIsSubscribed(isAuthenticated);
      } catch (error) {
        console.error("Error checking session:", error);
        // Update localStorage to reflect the error state
        localStorage.setItem(
          DISPATCH_SUBSCRIBED_KEY,
          JSON.stringify({
            subscribed: false,
            timestamp: Date.now(),
          })
        );
        setIsSubscribed(false);
      }
    };
    checkSession();
  }, []);

  useEffect(() => {
    setRandomArticles(
      shuffleArray(props.pageContext.otherArticles).slice(0, 2)
    );
  }, []);

  const formattedDate = frontmatter.date
    ? format(new Date(frontmatter.date), "MMMM dd, yyyy")
    : null;

  return (
    <>
      <Layout
        maxWidth="xl"
        pageTitle={frontmatter.title}
        pageDescription={frontmatter.description}
        pageSlug={frontmatter.slug}
        pageImageUrl={heroImageOriginalUrl}
        seoImageUrl={heroImageOriginalUrl}
        pageType={props.pageContext.isArticle ? "article" : "newsletter"}
        authorName={frontmatter.authorName}
        datePublished={frontmatter.date}
        dateModified={frontmatter.date}
        articleTags={frontmatter.tags}
        structuredData={{
          "@context": "https://schema.org",
          "@type": props.pageContext.isArticle ? "BlogPosting" : "NewsArticle",
          headline: frontmatter.title,
          image: [`https://www.jetpackcompose.app${heroImageOriginalUrl}`],
          datePublished: frontmatter.date,
          dateModified: frontmatter.date,
          keywords:
            frontmatter.keywords ||
            "Android development, Jetpack Compose, mobile development",
          author: [
            {
              "@type": "Person",
              name: frontmatter.authorName,
              url: frontmatter.authorProfileUrl,
            },
          ],
          publisher: {
            "@type": "Organization",
            name: "JetpackCompose.app",
            logo: {
              "@type": "ImageObject",
              url: "https://www.jetpackcompose.app/logo.png",
            },
          },
          description: frontmatter.description,
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": `https://www.jetpackcompose.app${frontmatter.slug}`,
          },
          isAccessibleForFree: !containsSubscriptionOnlyContent,
          ...(containsSubscriptionOnlyContent && {
            hasPart: [
              {
                "@type": "WebPageElement",
                isAccessibleForFree: true,
                cssSelector: ".excerpt",
              },
              {
                "@type": "WebPageElement",
                isAccessibleForFree: false,
                cssSelector: ".subscription-only-content",
              },
            ],
          }),
        }}
      >
        <div className="topLevelContainer">
          <div className="sideColumn"></div>
          <Container maxWidth="md" className="mainColumn">
            <div className="articlePostContainer">
              <div className="postHeaderContainer">
                <h1 className="postHeader">{frontmatter.title}</h1>
                <div className="postDescription">{frontmatter.description}</div>
                <div className="postSubheader">
                  <Avatar
                    name={frontmatter.authorName}
                    image={frontmatter.authorImageUrl}
                  />
                  <span className="authorName">
                    {frontmatter.authorName + " "}{" "}
                  </span>
                  {"  on "}
                  {formattedDate}
                </div>
                <div className="postHeaderTwitterFollowButton">
                  <TwitterFollowChip twitterUsername="vinaygaba" />
                </div>
                <ShareRow
                  url={articleUrl}
                  articleTitle={frontmatter.title}
                  articleDescription={frontmatter.description}
                />
              </div>
              {(props.pageContext.isArticle || !isSubscribed) && (
                <GatsbyImage
                  image={heroImage as IGatsbyImageData}
                  className="heroImage"
                  alt="Hero Image"
                />
              )}

              {!props.pageContext.isArticle && isSubscribed && (
                <NewsletterMainSponsor
                  ctaUrl="https://engineering.squarespace.com/blog/2024/unfolds-modern-mobile-release-process-and-the-subtle-art-of-making-them-boring?utm_source=jetpack&utm_medium=newsletter&utm_campaign=julysponsors"
                  ctaText="Read Article"
                />
              )}

              <MDXProvider
                components={{
                  wrapper: ({ children }) => {
                    interface MDXChild {
                      props?: Record<string, unknown> & {
                        "data-excerpt"?: boolean;
                      };
                    }

                    const filteredChildren = children.filter(
                      (child: unknown): child is MDXChild =>
                        child !== null &&
                        typeof child === "object" &&
                        "props" in child &&
                        typeof child.props === "object" &&
                        child.props !== null &&
                        "data-excerpt" in child.props
                    );

                    if (!isSubscribed) {
                      if (containsSubscriptionOnlyContent) {
                        const unfilteredChildren = children.filter(
                          (child: unknown): child is MDXChild =>
                            child !== null &&
                            typeof child === "object" &&
                            (!("props" in child) ||
                              typeof child.props !== "object" ||
                              child.props === null ||
                              !("data-excerpt" in child.props))
                        );
                        return (
                          <>
                            <div className="excerpt">{filteredChildren}</div>
                            <div className="subscription-only-content subscription-only-hidden">
                              {unfilteredChildren}
                            </div>
                          </>
                        );
                      } else {
                        return <>{children}</>;
                      }
                    }
                    return <>{children}</>;
                  },
                }}
              >
                <MDXRenderer
                  className="blog-post-content"
                  frontmatter={frontmatter}
                  twitterShareUrl={twitterShareUrl}
                  featuredJobs={props.pageContext.featuredJobs}
                >
                  {body}
                </MDXRenderer>
              </MDXProvider>

              {containsSubscriptionOnlyContent && !isSubscribed && (
                <BlurryNewsletterRow />
              )}

              {(!containsSubscriptionOnlyContent || isSubscribed) && (
                <>
                  <ShareRow
                    url={articleUrl}
                    articleTitle={frontmatter.title}
                    articleDescription={frontmatter.description}
                  />
                  <Divider className="divider" />
                  <AuthorCard
                    authorName={frontmatter.authorName}
                    authorImageUrl={frontmatter.authorImageUrl}
                  />
                  <SimilarContentRow
                    content={randomArticles}
                    title="If you like this article, you might also like:"
                    columnSize="two"
                  />
                  {props.pageContext.isArticle ? (
                    <NewsletterRow />
                  ) : (
                    <NewsletterRow
                      tagline={
                        <>
                          Think of us as your Android barista – we know your
                          order, and yes, we add the extra shot of clarity
                        </>
                      }
                    />
                  )}
                </>
              )}
            </div>
          </Container>
          <div className="sideColumn"></div>
        </div>
      </Layout>
    </>
  );
}

const subscribeUser = async (user: any) => {
  const response = await fetch("/api/subscribe", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: user.email,
      referringSite: window.location.href,
    }),
  });

  if (response.ok) {
  } else {
    console.error("Error subscribing user to newsletter");
  }
};
